import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout'

const FirstPage = () => import('../pages/tab/FirstPage.vue')
const Home = () => import('../pages/tab/Home.vue')
const Login = () => import('../pages/tab/Login.vue')
const NewLogin = () => import('../pages/tab/NewLogin.vue')
const TrialUse = () => import('../pages/tab/TrialUse.vue')
const ExamView = () => import('../pages/tab/ExamView.vue')
const SiteExamView = () => import('../pages/tab/SiteExamView.vue')
const SystemView = () => import('../pages/tab/SystemView.vue')
const BasicView = () => import('../pages/tab/BasicView.vue')
const QuestionBankView = () => import('../pages/tab/QuestionBankView.vue')
const VDAView = () => import('../pages/tab/VDAView.vue')
const StudyView = () => import('../pages/tab/StudyView.vue')
const NoneTabTitleView = () => import('../pages/tab/NoneTabTitleView.vue')


// 最外层首页
const HomePage = () => import('../pages/frist-page/HomePage.vue')
const SolutionIndex = () => import('../pages/frist-page/solution/SolutionIndex.vue')
const SolutionDetail = () => import('../pages/frist-page/solution/SolutionDetail.vue')
const AboutMe = () => import('../pages/frist-page/AboutMe.vue')
const QueBankSystem = () => import('../pages/frist-page/QueBankSystem.vue')
// 产品介绍
const ProductView = () => import('../pages/frist-page/product-description/ProductView.vue')
const ProductSonPage1 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage1.vue')
const ProductSonPage2 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage2.vue')
const ProductSonPage3 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage3.vue')
const ProductSonPage4 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage4.vue')
const ProductSonPage5 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage5.vue')
const ProductSonPage6 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage6.vue')
const ProductSonPage7 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage7.vue')
const ProductSonPage8 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage8.vue')
const ProductSonPage9 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage9.vue')
const ProductSonPage10 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage10.vue')
const ProductSonPage11 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage11.vue')
const ProductSonPage12 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage12.vue')
const ProductSonPage13 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage13.vue')
const ProductSonPage14 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage14.vue')
const ProductSonPage15 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage15.vue')
const ProductSonPage16 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage16.vue')
const ProductSonPage17 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage17.vue')
const ProductSonPage18 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage18.vue')
const ProductSonPage19 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage19.vue')
const ProductSonPage20 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage20.vue')
const ProductSonPage21 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage21.vue')
const ProductSonPage22 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage22.vue')
const ProductSonPage23 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage23.vue')
const ProductSonPage24 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage24.vue')
const ProductSonPage25 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage25.vue')
const ProductSonPage26 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage26.vue')
const ProductSonPage27 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage27.vue')
const ProductSonPage28 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage28.vue')
const ProductSonPage29 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage29.vue')
const ProductSonPage30 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage30.vue')
const ProductSonPage31 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage31.vue')
const ProductSonPage32 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage32.vue')
const ProductSonPage33 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage33.vue')
const ProductSonPage34 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage34.vue')
const ProductSonPage35 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage35.vue')
const ProductSonPage36 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage36.vue')
const ProductSonPage37 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage37.vue')
const ProductSonPage38 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage38.vue')
const ProductSonPage39 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage39.vue')
const ProductSonPage40 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage40.vue')
const ProductSonPage41 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage41.vue')
const ProductSonPage42 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage42.vue')
const ProductSonPage43 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage43.vue')
const ProductSonPage44 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage44.vue')
const ProductSonPage45 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage45.vue')
const ProductSonPage46 = () => import('../pages/frist-page/product-description/type-subpage/ProductSonPage46.vue')
// 书籍详情
const Book1 = () => import('../pages/frist-page/book-detail/Book1.vue')
const Book2 = () => import('../pages/frist-page/book-detail/Book2.vue')
const Book3 = () => import('../pages/frist-page/book-detail/Book3.vue')
const Book4 = () => import('../pages/frist-page/book-detail/Book4.vue')
const Book5 = () => import('../pages/frist-page/book-detail/Book5.vue')
const Book6 = () => import('../pages/frist-page/book-detail/Book6.vue')
const Book7 = () => import('../pages/frist-page/book-detail/Book7.vue')
const Book8 = () => import('../pages/frist-page/book-detail/Book8.vue')
const Book9 = () => import('../pages/frist-page/book-detail/Book9.vue')
const Book10 = () => import('../pages/frist-page/book-detail/Book10.vue')
const Book11 = () => import('../pages/frist-page/book-detail/Book11.vue')
const Book12 = () => import('../pages/frist-page/book-detail/Book12.vue')
const Book13 = () => import('../pages/frist-page/book-detail/Book13.vue')
const Book14 = () => import('../pages/frist-page/book-detail/Book14.vue')


// 检查
const PlanPage = () => import('../pages/file-examine/PlanPage.vue')
const filePageOfNo = () => import('../pages/file-examine/filePageOfNo.vue')
const filePage = () => import('../pages/file-examine/filePage.vue')
const FileList = () => import('../pages/file-examine/FileList.vue')
const SitePage = () => import('../pages/site-examine/SitePage.vue')
const SiteList = () => import('../pages/site-examine/SiteList.vue')
const RulesPage = () => import('../pages/rules-examine/RulesPage.vue')
const RulesList = () => import('../pages/rules-examine/RulesList.vue')
const QuestionPoint = () => import('../pages/other/QuestionPoint.vue')
const QuestionDetail = () => import('../pages/other/QuestionDetail.vue')
const ControlPage = () => import('../pages/control/ControlPage.vue')
const ControlDetail = () => import('../pages/control/ControlDetial.vue')
const LearnPage = () => import('../pages/learn-center/LearnPage.vue')
const UserTest = () => import('../pages/learn-center/UserTest.vue')
const Exam = () => import('../pages/learn-center/Exam.vue')
const UserScore = () => import('../pages/learn-center/UserScore.vue')
const FollowExamine = () => import('../pages/follow/FollowExamine.vue')


// 系统设置
const OAStructure = () => import('../pages/system-settings/OAStructure.vue')
const FunctionManage = () => import('../pages/system-settings/FunctionManage.vue')
const Company_Dept = () => import('../pages/system-settings/Company_dept.vue')
const Post_setting = () => import('../pages/system-settings/Post_setting.vue')
const Person_Setting = () => import('../pages/system-settings/Person_Setting')
const Process_setting = () => import('../pages/system-settings/Process_setting.vue')
const RolePermissions = () => import('../pages/system-settings/RolePermissions.vue')
const JobFunMaintain = () => import('../pages/system-settings/JobFunMaintain.vue')
const ChangePassword = () => import('../pages/system-settings/ChangePassword.vue')
const TimeMonitoring = () => import('../pages/system-settings/TimeMonitoring.vue')
const ApprovalApplication = () => import('../pages/system-settings/ApprovalApplication.vue')


//基础设置
const RegionManage = () => import('../pages/basic-settings/RegionManage.vue')
const Category = () => import('../pages/basic-settings/Category.vue')
const FileClassManage = () => import('../pages/basic-settings/FileClassManage.vue')
const StandardSettings = () => import('../pages/basic-settings/StandardSettings.vue')
const ModuleSettings = () => import('../pages/basic-settings/ModuleSettings.vue')
const ProcessFunTab = () => import('../pages/basic-settings/ProcessFunTab.vue')
const NoCompliantItems = () => import('../pages/basic-settings/NoCompliantItems.vue')

// 题库维护
const Maintain = () => import('../pages/question-bank/Maintain.vue')
const Interim = () => import('../pages/question-bank/Interim.vue')
const Scrutiny = () => import('../pages/question-bank/Scrutiny.vue')
const Import = () => import('../pages/question-bank/Import.vue')
const PostFunTable = () => import('../pages/question-bank/PostFunTable.vue')
const ExamBank = () => import('../pages/question-bank/ExamBank.vue')
const ThemeImport = () => import('../pages/question-bank/ThemeImport.vue')
const StandardImport = () => import('../pages/question-bank/StandardImport.vue')
const Import7s = () => import('../pages/question-bank/Import7s.vue')



//统计分析
const StatisticView = () => import('../pages/tab/StatisticView.vue')
const Quarter = () => import('../pages/statistic-analysis/Quarter.vue')
const Monthly = () => import('../pages/statistic-analysis/Monthly.vue')
const DrawqStatist = () => import('../pages/statistic-analysis/DrawqStatist.vue')
const QuestionPoints = () => import('../pages/statistic-analysis/QuestionPoints.vue')
const AddQuestionRecord = () => import('../pages/statistic-analysis/AddQuestionRecord.vue')
const MonitorRecord = () => import('../pages/statistic-analysis/MonitorRecord.vue')
const AuditorStatistics = () => import('../pages/statistic-analysis/AuditorStatistics.vue')
const Statistics = () => import('../pages/statistic-analysis/Statistics.vue')
const DeptProcess = () => import('../pages/statistic-analysis/DeptProcess.vue')


Vue.use(VueRouter)

// pc端路由
const routerPC = [
    {
        path: '/NewLogin',
        component: NewLogin,
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/home',
        component: Home,
    },
    {
        path: '/first-page',
        component: FirstPage,
        redirect: '/home-page',
        children: [
            {
                path: '/trial-use',
                component: TrialUse,
                meta: {
                    showPage: true
                },
            },
            {
                path: '/home-page',
                component: HomePage,
                meta: {
                    showPage: true
                },
            },
            {
                path: '/solution-index',
                component: SolutionIndex,
                meta: {
                    showPage: true
                },
            },
            {
                path: '/solution-detail',
                component: SolutionDetail,
                meta: {
                    showPage: true
                },
            },
            {
                path: '/about-me',
                component: AboutMe,
                meta: {
                    showPage: true
                },
            },
            {
                path: '/que-bank-system',
                component: QueBankSystem,
                meta: {
                    showPage: true
                },
            },
            {
                path: '/product-view',
                component: ProductView,
                redirect: '/product-son-page1',
                meta: {
                    showPage: true
                },
                children: [
                    {
                        path: '/product-son-page1',
                        component: ProductSonPage1,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page2',
                        component: ProductSonPage2,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page3',
                        component: ProductSonPage3,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page4',
                        component: ProductSonPage4,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page5',
                        component: ProductSonPage5,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page6',
                        component: ProductSonPage6,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page7',
                        component: ProductSonPage7,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page8',
                        component: ProductSonPage8,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page9',
                        component: ProductSonPage9,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page10',
                        component: ProductSonPage10,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page11',
                        component: ProductSonPage11,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page12',
                        component: ProductSonPage12,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page13',
                        component: ProductSonPage13,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page14',
                        component: ProductSonPage14,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page15',
                        component: ProductSonPage15,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page16',
                        component: ProductSonPage16,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page17',
                        component: ProductSonPage17,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page18',
                        component: ProductSonPage18,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page19',
                        component: ProductSonPage19,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page20',
                        component: ProductSonPage20,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page21',
                        component: ProductSonPage21,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page22',
                        component: ProductSonPage22,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page23',
                        component: ProductSonPage23,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page24',
                        component: ProductSonPage24,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page25',
                        component: ProductSonPage25,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page26',
                        component: ProductSonPage26,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page27',
                        component: ProductSonPage27,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page28',
                        component: ProductSonPage28,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page29',
                        component: ProductSonPage29,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page30',
                        component: ProductSonPage30,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page31',
                        component: ProductSonPage31,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page32',
                        component: ProductSonPage32,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page33',
                        component: ProductSonPage33,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page34',
                        component: ProductSonPage34,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page35',
                        component: ProductSonPage35,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page36',
                        component: ProductSonPage36,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page37',
                        component: ProductSonPage37,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page38',
                        component: ProductSonPage38,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page39',
                        component: ProductSonPage39,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page40',
                        component: ProductSonPage40,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page41',
                        component: ProductSonPage41,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page42',
                        component: ProductSonPage42,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page43',
                        component: ProductSonPage43,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page44',
                        component: ProductSonPage44,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page45',
                        component: ProductSonPage45,
                        meta: {
                            showPage: true
                        },
                    },
                    {
                        path: '/product-son-page46',
                        component: ProductSonPage46,
                        meta: {
                            showPage: true
                        },
                    },
                ]
            },
        ]
    },
    {
        path: '/book1',
        component: Book1,
        meta: {
            showPage: true
        },
    },
    {
        path: '/book2',
        component: Book2,
        meta: {
            showPage: true
        },
    },
    {
        path: '/book3',
        component: Book3,
        meta: {
            showPage: true
        },
    }, {
        path: '/book4',
        component: Book4,
        meta: {
            showPage: true
        },
    }, {
        path: '/book5',
        component: Book5,
        meta: {
            showPage: true
        },
    }, {
        path: '/book6',
        component: Book6,
        meta: {
            showPage: true
        },
    },
    {
        path: '/book7',
        component: Book7,
        meta: {
            showPage: true
        },
    }, {
        path: '/book8',
        component: Book8,
        meta: {
            showPage: true
        },
    }, {
        path: '/book9',
        component: Book9,
        meta: {
            showPage: true
        },
    }, {
        path: '/book10',
        component: Book10,
        meta: {
            showPage: true
        },
    },
    {
        path: '/book11',
        component: Book11,
        meta: {
            showPage: true
        },
    },
    {
        path: '/book12',
        component: Book12,
        meta: {
            showPage: true
        },
    },
    {
        path: '/book13',
        component: Book13,
        meta: {
            showPage: true
        },
    },
    {
        path: '/book14',
        component: Book14,
        meta: {
            showPage: true
        },
    },
    // 审核检查
    {
        path: '/ExamView',
        component: ExamView,
        children: [
            {
                path: '/plan-page',
                component: PlanPage,
                meta: {
                    keepAlive: true,
                    ifDoFresh: false,
                },
            },
            {
                path: '/filepage-ofno',
                component: filePageOfNo,
                meta: {
                    keepAlive: true,
                    ifDoFresh: false,
                },
            },
            {
                path: '/file-page',
                component: filePage,
                name: 'filePage',
                meta: {
                    keepAlive: true,
                    ifDoFresh: false,
                },
            },
            {
                path: '/site-list',
                component: SiteList,
                name: 'SiteList',
                meta: {
                    keepAlive: true,
                },
            },
            {
                path: '/File-list',
                component: FileList,
                name: 'FileList',
                meta: {
                    keepAlive: true,
                },
            },

            {
                path: '/rules-list',
                component: RulesList,
                name: 'RulesList',
                meta: {
                    keepAlive: true,
                },
            },
            {
                path: '/QuestionPoint',
                component: QuestionPoint,
                meta: {
                    keepAlive: true,
                    ifDoFresh: false,
                },
            },
            {
                path: '/question-detail',
                component: QuestionDetail,
                name: 'QuestionDetail',
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/control-page',
                component: ControlPage,
                meta: {
                    keepAlive: true,
                    ifDoFresh: false,
                },
            },
            {
                path: '/control-detail',
                component: ControlDetail,
                name: 'ControlDetail',
                meta: {
                    keepAlive: false,
                },

            },


            {
                path: '/user-test',
                component: UserTest,
                name: 'UserTest',
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/exam',
                component: Exam,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/user-score',
                component: UserScore,
                name: 'UserScore',
                meta: {
                    keepAlive: false,
                },
            },

        ]
    },
    {
        path: 'noneTabTitle-view',
        component: NoneTabTitleView,
        children: [
            {
                path: '/follow-examine',
                component: FollowExamine,
                meta: {
                    keepAlive: false,
                },
            },
        ]
    },
    {
        path: '/siteExam-view',
        component: SiteExamView,
        children: [
            {
                path: '/site-page',
                component: SitePage,
                name: 'SitePage',
                meta: {
                    keepAlive: true,
                    ifDoFresh: false,
                },
            },
            {
                path: '/rules-page',
                component: RulesPage,
                meta: {
                    keepAlive: true,
                    ifDoFresh: false,
                },
            },
        ]
    },
    {
        path: '/study-View',
        component: StudyView,
        children: [
            {
                path: '/learn-page',
                component: LearnPage,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/file-learn',
                component: () => import('../pages/learn-center/fileLearn.vue'),
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/special-learn',
                component: () => import('../pages/learn-center/specialLearn.vue'),
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/designate',
                component: () => import('../pages/learn-center/designate.vue'),
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/assignStudy',
                component: () => import('../pages/learn-center/AssignStudy.vue'),
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/AspSubject',
                component: () => import('../pages/learn-center/AspSubject.vue'),
                meta: {
                    keepAlive: true,
                    ifDoFresh: false,
                },
            },
            {
                path: '/AssignTest',
                component: () => import('../pages/learn-center/AssignTest.vue'),
                name: 'AssignTest',
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/formal',
                component: () => import('../pages/learn-center/formal.vue'),
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/test',
                component: () => import('../pages/learn-center/test.vue'),
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/score',
                component: () => import('../pages/learn-center/score.vue'),
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/AssignScore',
                component: () => import('../pages/learn-center/AssignScore.vue'),
                name: 'AssignScore',
                meta: {
                    keepAlive: false,
                },
            },
        ]
    },
    // 系统
    {
        path: '/system-View',
        component: SystemView,
        redirect: '/function-manage',
        meta: {
            keepAlive: false,
        },
        children: [
            {
                path: '/oa-structure',
                component: OAStructure,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/function-manage',
                component: FunctionManage,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/Company_Dept',
                component: Company_Dept,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/Post_setting',
                component: Post_setting,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/Person_Setting',
                component: Person_Setting,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/Process_setting',
                component: Process_setting,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/role-permissions',
                component: RolePermissions,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/job-fun-maintain',
                component: JobFunMaintain,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/change-password',
                component: ChangePassword,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/time-monitoring',
                component: TimeMonitoring,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/approval-application',
                component: ApprovalApplication,
                meta: {
                    keepAlive: false,
                },
            },

        ]
    },
    //基础
    {
        path: '/basic-view',
        component: BasicView,
        redirect: '/region-manage',
        meta: {
            keepAlive: false,
        },
        children: [
            {
                path: '/region-manage',
                component: RegionManage,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/category',
                component: Category,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/file-class-manage',
                component: FileClassManage,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/standard-settings',
                component: StandardSettings,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/module-settings',
                component: ModuleSettings,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/process-fun-tab',
                component: ProcessFunTab,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/no-compliant-items',
                component: NoCompliantItems,
                meta: {
                    keepAlive: false,
                },
            },
        ]
    },
    {
        // 题库管理
        path: '/question-bank-view',
        component: QuestionBankView,
        redirect: '/maintain',
        meta: {
            keepAlive: false,
        },
        children: [
            {
                path: '/maintain',
                component: Maintain,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/interim',
                component: Interim,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/scrutiny',
                component: Scrutiny,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/import',
                component: Import,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/post-fun-table',
                component: PostFunTable,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/exam-bank',
                component: ExamBank,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/theme-import',
                component: ThemeImport,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/standard-import',
                component: StandardImport,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/import7s',
                component: Import7s,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/maturity',
                component: () => import('@/pages/question-bank/Maturity.vue'),
            }
        ]
    },
    //统计分析
    {
        path: '/statistic-view',
        component: StatisticView,
        redirect: '/Quarter',
        meta: {
            keepAlive: false,
        },
        children: [
            {
                path: '/Quarter',
                component: Quarter,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/Monthly',
                component: Monthly,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/DrawqStatist',
                component: DrawqStatist,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/QuestionPoints',
                component: QuestionPoints,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/AddQuestionRecord',
                component: AddQuestionRecord,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/MonitorRecord',
                component: MonitorRecord,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/AuditorStatistics',
                component: AuditorStatistics,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/Statistics',
                component: Statistics,
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/dept-process',
                component: DeptProcess,
                meta: {
                    keepAlive: false,
                },
            },
        ]
    },
    //VDA63
    {
        path: '/VDA-view',
        component: VDAView,
        redirect: '/Supplier',
        meta: {
            keepAlive: false,
        },
        children: [
            {
                path: '/Supplier',
                component: () => import('../pages/VDA/Supplier.vue'),
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/InfoRegister',
                component: () => import('../pages/VDA/InfoRegister.vue'),
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/AnnualAuditPlan',
                component: () => import('../pages/VDA/AnnualAuditPlan.vue'),
                meta: {
                    keepAlive: true,
                },
            },
            {
                path: '/SupplierAudit',
                component: () => import('../pages/VDA/SupplierAudit.vue'),
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/Audit',
                component: () => import('../pages/VDA/Audit.vue'),
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/ProblemSynthesis',
                component: () => import('../pages/VDA/ProblemSynthesis.vue'),
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/MeasureClosure',
                component: () => import('../pages/VDA/MeasureClosure.vue'),
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/DataSynthesis',
                component: () => import('../pages/VDA/DataSynthesis.vue'),
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/Process',
                component: () => import('../pages/VDA/Process.vue'),
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/SupplierQuestion',
                component: () => import('../pages/VDA/SupplierQuestion.vue'),
                meta: {
                    keepAlive: false,
                },
            }
        ]
    },
    // 联系我们
    {
        path: '/contact-view',
        component: () => import('../pages/tab/ContactView'),
        redirect: '/ContactUs',
        meta: {
            keepAlive: false,
        },
        children: [
            {
                path: '/ContactUs',
                component: () => import('../pages/contact/ContactUs'),
                meta: {
                    keepAlive: false,
                }
            },
            {
                path: '/Service',
                component: () => import('../pages/contact/Service'),
                meta: {
                    keepAlive: false,
                }
            }
        ]
    },
    //用户设置
    {
        path: '/user-manage-view',
        component: () => import('../pages/tab/UserManageView.vue'),
        redirect: '/membership-level',
        meta: {
            keepAlive: false,
        },
        children: [
            {
                path: '/membership-level',
                component: () => import('../pages/user-manage/MembershipLevel.vue'),
                meta: {
                    keepAlive: false,
                },
            },
            {
                path: '/level-mange',
                component: () => import('../pages/user-manage/LevelMange.vue'),
                meta: {
                    keepAlive: true,
                    ifDoFresh: false,
                },
            },
            {
                path: '/level-detail',
                component: () => import('../pages/user-manage/LevelDetail.vue'),
                name: 'LevelDetail',
                meta: {
                    keepAlive: false,
                },
            },

        ]
    },
    {
        path: '/',
        redirect: '/first-page'
    },
    {
        name: '404',
        path: '/404',
        component: () => import('../components/router-page/NotFound.vue'),
    },
    {
        name: '500',
        path: '/500',
        component: () => import('../components/router-page/ServerError.vue'),
    },
    // 企业评估路由
    {
        path: '/historyPage',
        component: () => import('../pages/evaluation/HistoryPage.vue'),
    },
    {
        path: '/answer',
        component: () => import('../pages/evaluation/Answer.vue'),
    },
    {
        path: '/indexreport',
        component: () => import('../pages/evaluation/IndexReport.vue'),
    },

    // 说明书路由
    {
        path: '/specification',
        component: Layout,
        children: [
            {
                path: '/specification',
                component: () => import('../views/specification/index.vue'),
                meta: { title: '说明书首页', showPage: true }
            }
        ]
    },
    {
        path: '/Summarize',
        component: Layout,
        redirect: '/specification',
        meta: {
            keepAlive: false,
            showPage: true
        },
        children: [
            {
                path: '/Setting',
                component: () => import('../views/summarize/setting.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
            {
                path: '/Expository',
                component: () => import('../views/summarize/expository.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            }
        ]
    },
    {
        path: '/BankSetting',
        component: Layout,
        meta: {
            keepAlive: false,
            showPage: true
        },
        children: [
            {
                path: '/BankSettingIndex',
                component: () => import('../views/BankSetting/index.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
        ]
    },
    {
        path: '/checkProcess',
        component: Layout,
        meta: {
            keepAlive: false,
            showPage: true
        },
        children: [
            {
                path: '/checkProessIndex',
                component: () => import('../views/checkProcess/index.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
            {
                path: '/checkProessProcess2',
                component: () => import('../views/checkProcess/Process2.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
            {
                path: '/checkProessProcess3',
                component: () => import('../views/checkProcess/Process3.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
        ]
    },
    {
        path: '/bankManage',
        component: Layout,
        meta: {
            keepAlive: false,
            showPage: true
        },
        children: [
            {
                path: '/bankManage1',
                component: () => import('../views/bankManage/6.1.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
            {
                path: '/bankManage2',
                component: () => import('../views/bankManage/6.2.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
            {
                path: '/bankManage3',
                component: () => import('../views/bankManage/6.3.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
            {
                path: '/bankManage4',
                component: () => import('../views/bankManage/6.4.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
            {
                path: '/bankManage5',
                component: () => import('../views/bankManage/6.5.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
            {
                path: '/bankManage6',
                component: () => import('../views/bankManage/6.6.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
        ]
    },
    {
        path: '/FunctionalProfile',
        component: Layout,
        children: [
            {
                path: '/FunctionalProfile',
                component: () => import('../views/functional-profile/FunctionalProfile.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            }
        ]
    },
    {
        path: '/InitialValue',
        component: Layout,
        redirect: '/InitialValue',
        meta: {
            keepAlive: false,
            showPage: true
        },
        children: [
            {
                path: '/InitialValue',
                component: () => import('../views/initial-value/index.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
            {
                path: '/CompanySetup',
                component: () => import('../views/initial-value/CompanySetup.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
            {
                path: '/SectorSetup',
                component: () => import('../views/initial-value/SectorSetup.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
            {
                path: '/PostSetting',
                component: () => import('../views/initial-value/PostSetting.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
            {
                path: '/Functional',
                component: () => import('../views/initial-value/Functional.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
            {
                path: '/Personnel',
                component: () => import('../views/initial-value/Personnel.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
            {
                path: '/Permission',
                component: () => import('../views/initial-value/Permission.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
            {
                path: '/PersonalCenter',
                component: () => import('../views/initial-value/PersonalCenter.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
            {
                path: '/District',
                component: () => import('../views/initial-value/District.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
            {
                path: '/DesignCategory',
                component: () => import('../views/initial-value/DesignCategory.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
            {
                path: '/Safeguard',
                component: () => import('../views/initial-value/Safeguard.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
        ]
    },
    {
        path: '/draw-statistics',
        component: Layout,
        redirect: 'draw-statistics',
        children: [
            {
                path: '/draw-statistics',
                component: () => import('../views/statistics/DrawStatistics.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            }
        ],

    },
    {
        path: '/special-subject-study',
        component: Layout,
        redirect: '/special-subject-study',
        children: [
            {
                path: '/special-subject-study',
                component: () => import('../views/syudy-modules/SpecialSubjectStudy.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
            {
                path: '/file-study',
                component: () => import('../views/syudy-modules/FileStudy.vue'),
                meta: {
                    keepAlive: false,
                    showPage: true
                }
            },
        ]
    },

]

// 移动端路由
const routerM = [
    {
        path: '/',
        component: () => import('../pages/mobile/HomePage.vue'),
        redirect: '/home-page',
    },
    {
        path: '/home-page',
        component: () => import('../pages/mobile/HomePage.vue'),
    },
    {
        path: '/solution',
        component: () => import('../pages/mobile/solution.vue'),
    },
    {
        path: '/solutionDetails',
        component: () => import('../pages/mobile/solutionDetails.vue'),
    },
    {
        path: '/aboutUs',
        component: () => import('../pages/mobile/aboutUs.vue'),
    },
    {
        path: '/systemConstruction',
        component: () => import('../pages/mobile/systemConstruction.vue'),
    },
]

var routes = [];
if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    routes = routerM
} else {
    routes = routerPC
}

const router = new VueRouter({
    routes
})

// 点击重复路径报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}

if (!(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    router.beforeEach((to, from, next) => {
        if (to.matched.length == 0) return next('/404')
        //获取token
        const tokenStr = window.sessionStorage.getItem('token')
        if (tokenStr && (to.path == '/NewLogin')) return next()
        if (!tokenStr && (to.path != '/NewLogin' && !to.meta.showPage)) return next('/NewLogin')
        next()
    })
}
export default router 
